<template>
	<v-card flat elevation="0" class="min-height-screen">
		<v-toolbar flat class="border-b-2 border--secondary px-0 py-3 !h-85px">
			<a :href="`${baseUrl}/me/cart?token=${authToken}`" class="text-decoration-none mr-2">
				<v-icon color="primary">mdi-arrow-left</v-icon>
			</a>

			<v-toolbar-title><strong>Checkout</strong></v-toolbar-title>
		</v-toolbar>

		<div class="px-4 mt-6 w-full h-full min-height-screen">
			<div
				v-if="isLoading"
				class="h-full w-full d-flex justify-center align-center"
				style="height: 400px"
			>
				<loader />
			</div>
			<v-form v-else ref="checkoutFormRef" @submit.prevent="handleInsertOrder">
				<v-row>
					<v-col cols="12" class="py-0">
						<label
							for="firstname"
							class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
							>First name</label
						>
						<v-text-field
							id="firstname"
							:rules="[requiredRule]"
							v-model="user.firstName"
							placeholder="Enter firstname"
							class="rounded-small"
							outlined
						/>
					</v-col>
					<v-col cols="12" class="py-0">
						<label
							for="lastname"
							class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
							>Last name</label
						>
						<v-text-field
							id="lastname"
							:rules="[requiredRule]"
							v-model="user.lastName"
							placeholder="Enter lastname"
							class="rounded-small"
							outlined
						/>
					</v-col>

					<v-col cols="12" class="py-0">
						<label
							for="email"
							class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
							>Email Address</label
						>
						<v-text-field
							id="email"
							outlined
							:rules="[requiredRule, emailRule]"
							v-model="user.emailId"
							class="rounded-small"
							placeholder="Enter Email Address"
						/>
					</v-col>
					<v-col cols="12" class="py-0">
						<div>
							<label
								for="phone-number"
								class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
								>Phone Number</label
							>
							<v-text-field
								id="phone-number"
								outlined
								:rules="[requiredRule, numberRule, mobileNoRule]"
								v-model="user.mobileNo"
								class="rounded-small"
								placeholder="Enter Phone Number"
							/>
						</div>
					</v-col>
					<v-col cols="12" class="py-0">
						<label
							for="post-code"
							class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
							>Post Code</label
						>
						<v-text-field
							id="post-code"
							outlined
							:rules="[requiredRule]"
							v-model="user.zipCode"
							class="rounded-small"
							placeholder="Enter Post code"
						/>
					</v-col>
					<v-col cols="12" class="py-0">
						<label
							for="address"
							class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
							>Address
							<button
								v-if="
									Array.isArray(this.addresses) && this.addresses.length > 0 && this.invalidZipCode
								"
								class="ml-2 text-xs primary--text text-decoration-underline"
								@click="
									invalidZipCode = false
									user.address = ''
								"
							>
								Select from the list?
							</button>
						</label>
						<v-select
							v-if="!invalidZipCode"
							outlined
							id="address"
							:items="addresses"
							v-model="user.address"
							:rules="[requiredRule]"
							class="rounded-small"
							placeholder="Select Address"
							@change="handleAddressChange"
						/>
						<v-textarea
							v-else
							id="address"
							outlined
							:rules="[requiredRule]"
							v-model="user.address"
							:disabled="isDisabledAddress"
							class="rounded-small"
							placeholder="Enter Address"
						></v-textarea>
					</v-col>
					<v-col cols="12" class="py-0 mb-16">
						<label
							for="extra-address-details"
							class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
							>Extra Address details</label
						>
						<v-textarea
							id="extra-address-details"
							outlined
							v-model="user.extraAddressInfo"
							class="rounded-small"
							placeholder="Enter extra address details"
						/>
					</v-col>
					<v-col cols="12" class="mb-8 mt-8">
						<v-btn
							type="submit"
							color="primary"
							class="pa-3 h-auto w-full text-capitalize d-block rounded-small"
							:disabled="isSubmitting"
						>
							<strong class="text-capitalize text-h6 font-weight-bold">Place Order</strong>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</div>
	</v-card>
</template>

<script>
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import debounceFn from 'debounce-fn'
import AuthMixin from '@/mixins/auth'
import store from '@/store'
import Loader from '@/components/Loader.vue'
import { requiredRule, emailRule, numberRule, mobileNoRule } from '@/validations'
import { getAddresses } from '@/services/address'
import { getUserCheckoutAddress } from '@/services/profile'
import { initiateOrder } from '@/services/order'
import { SUCCESS } from '@/constants/status-code'

export default {
	name: 'PlaceOrderPage',
	mixins: [AuthMixin],
	components: {
		Loader,
	},
	data() {
		return {
			requiredRule,
			emailRule,
			numberRule,
			mobileNoRule,
			isLoading: false,
			isSubmitting: false,
			invalidZipCode: false,
			isDisabledAddress: false,
			addresses: [],
			user: {
				firstName: '',
				lastName: '',
				emailId: '',
				mobileNo: '',
				address: '',
				zipCode: '',
				extraAddressInfo: '',
			},
		}
	},
	beforeRouteEnter(_to, _from, next) {
		const { cartProducts } = store.state
		const hasCartProducts = Array.isArray(cartProducts) && cartProducts.length > 0
		if (!hasCartProducts) {
			// if no cart products
			next((vm) => {
				vm.$router.push({ name: 'shopping-cart', query: { token: vm.authToken } })
			})
			return
		}
		next()
	},
	created() {
		this.fetchUserCheckoutAddress()
	},
	mounted() {
		this.$watch(
			() => this.user.zipCode,
			debounceFn(
				() => {
					this.fetchAddressesList()
				},
				{ wait: 500 }
			)
		)
	},
	watch: {
		'user.zipCode': {
			handler() {
				// has address
				if (this.user.address) {
					// if address disabled then enable it
					if (this.isDisabledAddress) {
						this.isDisabledAddress = false
					}
					this.user.address = '' // clear address
				}
			},
		},
	},
	computed: {
		cartProducts() {
			return this.$store.state.cartProducts
		},
		discountCodeId() {
			return this.$store.state.voucherCodeId
		},
	},
	methods: {
		handleAddressChange(value) {
			if (value === 'Not in the List?') {
				this.isDisabledAddress = false
				this.user.address = ''
			} else {
				this.isDisabledAddress = true
			}
			this.invalidZipCode = true // show textarea
		},
		async fetchUserCheckoutAddress() {
			this.isLoading = true
			const res = await getUserCheckoutAddress(this.authToken)
			const { data: user, statusCode } = res.data

			if (statusCode === SUCCESS) {
				this.user.firstName = user.firstName
				this.user.lastName = user.lastName
				this.user.emailId = user.emailId
				this.user.mobileNo = user.mobileNo
				this.user.zipCode = user.zipCode
				this.user.extraAddressInfo = user.extraAddressInfo
				this.$nextTick(() => {
					this.user.address = user.address
				})
			}
			this.isLoading = false
		},
		async handleInsertOrder() {
			if (!this.$refs.checkoutFormRef.validate()) return
			const orderItems = this.cartProducts.map((product) => ({
				productId: product.id,
				qtyOrdered: product.quantity,
			}))

			const order = {
				firstName: this.user.firstName,
				lastName: this.user.lastName,
				emailId: this.user.emailId,
				mobileNo: this.user.mobileNo,
				address: this.user.address,
				zipCode: this.user.zipCode,
				extraAddressInfo: this.user.extraAddressInfo,
				ipAddress: '',
				platform: 'Mobile',
				orderItems,
				discountId: this.discountCodeId,
			}

			try {
				const res = await initiateOrder(this.authToken, { formData: order })
				const { data, statusCode } = res.data

				if (statusCode !== SUCCESS) throw new Error('something went wrong')

				const { clientSecret, orderNumber } = data

				if (!clientSecret || !orderNumber) throw new Error('No client secret or no order number')

				this.$router.push({
					name: 'checkout',
					query: { token: this.authToken, client_secret: clientSecret },
				})
			} catch (e) {
				console.log(e)
			}
		},
		async fetchAddressesList() {
			if (!this.user.zipCode) return

			try {
				const res = await getAddresses(this.authToken, { zipcode: this.user.zipCode.toUpperCase() })
				const { addresses } = res.data

				if (Array.isArray(addresses)) {
					// remove blank character ('') and join (, ) and trim address
					const addressList = addresses.map((address) => address.filter(String).join(', ').trim())
					this.addresses = [...addressList, 'Not in the List?']
					// if in address list then show select box else textarea
					this.invalidZipCode = !addressList.includes(this.user.address)
				}
			} catch (e) {
				const { response } = e
				if (response) {
					const { status } = response
					// status code 4xx - 5xx no data
					if (status >= 400) {
						this.addresses = []
						this.invalidZipCode = true
					}
				}
			}
		},
	},
}
</script>
