/* eslint-disable implicit-arrow-linebreak */
import { Api } from '@/services/api'

const ADDRESS_API_KEY = process.env.VUE_APP_GET_ADDRESS_API_KEY

export const getAddresses = async (token, { zipcode }) =>
	Api(token).get(
		// eslint-disable-next-line comma-dangle
		`https://api.getAddress.io/find/${zipcode}?api-key=${ADDRESS_API_KEY}&format=true&sort=true`
	)

export default undefined
