/* eslint-disable implicit-arrow-linebreak */
import { Api } from '@/services/api'

export const getUserProfileDetails = async (token) => Api(token).get('/V1/Customer/GetProfile')

export const updateUserProfileDetails = async (token, { formData }) =>
	Api(token).post('/V1/Customer/SetProfile', formData)

export const changeUserPassword = async (token, { formData }) =>
	Api(token).post('/V1/Authentication/ChangePassword', formData)

export const getUserCheckoutAddress = async (token) =>
	Api(token).get('/V1/Customer/GetCheckoutAddress')

export const deleteUserAccount = async (token) => Api(token).delete('/V1/Customer/DeleteCustomer')

export default undefined
